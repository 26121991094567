export const podroomColors = {
  green        : "#48E4B7",
  yellow       : "#FCEDBD",
  orange       : "#FFCF37",
  cyan         : "#1BD4E9",
  black        : "#040505",
  maroon       : "#AA007C",
  lightGray    : "#F9F9F9",
  lessLightGray: "#F2F2F2",
  darkGray     : "#40404"
}