import React from "react"
import { CenterContainerStyled, CenterContainerStyledProps } from "./css/CenterContainerStyled.css";

interface CenterContainerProps {
  className?: string;
}

type CenterContainerPropsType = CenterContainerProps & CenterContainerStyledProps;

const CenterContainer: React.FC<CenterContainerPropsType> = (props) => (

    <CenterContainerStyled
      {...props}
      >
        {props.children}
    </CenterContainerStyled>

)

export default CenterContainer;