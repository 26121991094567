import { css } from "styled-components"

export const navigationMobile = css`
  
  nav.mobile {

    position: fixed;
    background-color: black;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 5;
    padding: 20px;
    padding-top: 100px;
    box-sizing: border-box;
    transition: transform 300ms;
    overflow-y: scroll;

    a, p, span {
      color: white;
      font-size: 28px;
    }
    
    .nav-item {
      display: block;
      margin-bottom: 40px;
    }

    .nav-item:not(:last-child) {
      
    }

    .nav-item,
    a.nav-item,
    p.nav-item--dropdown-p { 
      
    }

    .nav-dropdown {

      &__submenu {

        /* display: none; */
        padding-top: 30px;
        padding-left: 20px;

        &__item {

          &__cat-text {
            &:before {
              content: "-";
              display: inline-block;
              color: white;
              margin-right: 10px;
            }
          }

          margin-bottom: 20px;
          display: block;

          &.has-sub-submenu {

            position: relative;
            
            &:after {
              position: absolute;
              content: "";
              right: 40px;
              top: 12px;
              width: 0;
              height: 0;
              border-style: solid;
              vertical-align: middle;
              z-index: 10;
              border-width: 12px 12px 0 12px;
              border-color: white transparent transparent transparent;
            }
            
          }

          &__icon {
            display: none;
          }

          &__links.sub-submenu {

            display: none;

            &.open {
              display: block;
              padding-top: 40px;
            }

            &:before {

            }

            a { 

              display: block;
              padding-left: 40px;

              &:not(:last-child) {
                margin-bottom: 20px;
              }

              &:before {
                content: "-";
                display: inline-block;
                color: white;
                margin-right: 10px;
              }

              &:hover {

              }
            }

          } /** end of &__links.sub-submenu */

          &:hover {
            .sub-submenu {

            }
          }

        } /** end of &__item */

        &:after {
          content: "";
          /* width:  */
        }

      } /** end of &__submenu */


      /** 
      
          For .nav-dropdown

      */

      &:hover {

        .submenu,
        &::before,
        &::after {

        }

      }

      &:before {

      }

      &:after {

      }

    }

  }

`

