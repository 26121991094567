import styled from "styled-components"
import { logoWithNav } from "./logoWithNav.css";

interface LogoWithNavStyledPropsI {
  isHamburgerMenuOpen: boolean
}

export const LogoWithNavStyled = styled.div<LogoWithNavStyledPropsI>`

  ${logoWithNav}

  .hamburger-menu {

    filter: ${props => props.isHamburgerMenuOpen ? `invert(1)` : `invert(0)` };

    /* ${props => props.isHamburgerMenuOpen
      ? 
      `
      width: 2rem;
      height: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background: transparent;
      background-image: none !important;
      border: none;
      `
      : ``
    }  */

    /* span {

      opacity: ${props => props.isHamburgerMenuOpen ? `1` : `0`};

      :first-child {
        transform: ${({ isHamburgerMenuOpen }) => isHamburgerMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};
       }

      :nth-child(2) {
        opacity: ${({ isHamburgerMenuOpen }) => isHamburgerMenuOpen ? '0' : '1'};
        transform: ${({ isHamburgerMenuOpen }) => isHamburgerMenuOpen ? 'translateX(20px)' : 'translateX(0)'};
      }

      :nth-child(3) {
        transform: ${({ isHamburgerMenuOpen }) => isHamburgerMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    } */
  }

`