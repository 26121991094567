import styled from "styled-components"
import { navigationDesktop } from "./navigation.desktop.css";
import { navigationMobile } from "./navigation.mobile.css";

interface NavigationStyledPropsI {
  isHamburgerMenuOpen: boolean;
  desktopMainMenuTextColor?: string;
}

export const NavigationStyled = styled.div<NavigationStyledPropsI>`

  ${navigationDesktop}

  ${navigationMobile}

  nav.mobile {
    transform: ${props => props.isHamburgerMenuOpen ? `translateX(0)` : `translateX(100%)`};
  }

  nav.desktop {
    .nav-item,
    a.nav-item,
    p.nav-item--dropdown-p { 
      color: ${props => props.desktopMainMenuTextColor ?? "black"} !important;
    }
  }
    
  
`