import { css } from "styled-components"
import { podroomColors } from "./_/colors"
import { podroomFonts } from "./_/fonts"

export const podroomTheme = {
  color: {
    ...podroomColors
  },
  fonts: {
    ...podroomFonts
  }
}

export type PodroomThemeType = typeof podroomTheme;