import React from "react"
import { FullWidthContainerStyled, FullWidthContainerStyledProps } from "./css/FullWidthContainerStyled.css";

interface FullWidthContainerProps {
  className?: string;
}

type FullWidthContainerPropsType = FullWidthContainerProps & FullWidthContainerStyledProps;

const FullWidthContainer: React.FC<FullWidthContainerPropsType> = (props) => (

    <FullWidthContainerStyled
      {...props}
      >
        {props.children}
    </FullWidthContainerStyled>

)

export default FullWidthContainer;