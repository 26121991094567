import { css } from "styled-components"

export const navigationDesktop = css`
  
  nav.desktop {

    .nav-item {
      display: inline-block;
    }

    .nav-item:not(:last-child) {
      margin-right: 50px;
    }

    .nav-item,
    a.nav-item,
    p.nav-item--dropdown-p { 
      font-size: 18px;
      font-weight: bold;
    }

    .nav-dropdown {
      
      position: relative;
      cursor: default;

      &__submenu {
        visibility: hidden;
        opacity: 0;
        transition: all 300ms;
        transform: translateX(-10px) translateY(20px);
        position: absolute;
        z-index: 3;
        background-color: white;
        min-width: 260px;
        padding: 40px 0 0 30px;
        box-sizing: border-box;
        border: 1px solid #e0e0e0;
        border-radius: 8px;

        &__item {

          position: relative;
          padding-bottom: 30px;
          padding-right: 30px;
          display: block;

          > img, > p {
            transition: transform 300ms;
          }

          &__icon {
            margin-bottom: 13px;
          }

          &__links.sub-submenu {

            display: none;
            position: absolute;
            left: 229px;
            background-color: white;
            padding: 30px;
            box-sizing: border-box;
            border: 1px solid #e0e0e0;
            border-left: none;
            z-index: 2;
            transform: translateZ(1px);
            top: -5px;
            width: max-content;

            &:before {
              content: " ";
              position: absolute;
              z-index: 5;
              transform: rotate(45deg);
              top: 30px;
              left: -11px;
              width: 20px;
              height: 20px;
              border-right: 1px solid #e0e0e0;
              border-top: 1px solid #e0e0e0;
              background-color: white;
            }

            a { 
              display: block;
              font-weight: normal;
              color: dimgrey;
              transition: color 300ms;

              &:not(:last-child) {
                margin-bottom: 10px;
              }

              &:hover {
                color: black;
              }
            }

          } /** end of &__links.sub-submenu */

          &.has-sub-submenu:hover { /** sub-submenu */

            .sub-submenu {
              display: block;
            }

            > img, > p {
              transform: translateX(10px);
            }
          }

        } /** end of &__item */

      } /** end of &__submenu */
      

      /** 
      
          For .nav-dropdown

      */

      &:hover {

        .submenu,
        &::before,
        &::after {
          visibility: visible;
          opacity: 1;
        }

        .submenu {
          transform: translateX(0px) translateY(20px);
        }

      }

      &:before {
        opacity: 0;
        visibility: hidden;
        transition: all 300ms;
        content: " ";
        position: absolute;
        top: 20px;
        height: 20px;
        background-color: transparent;
        width: 220px;
        left: 0;
      }

      &:after {
        opacity: 0;
        visibility: hidden;
        transition: all 300ms;
        content: " ";
        position: absolute;
        z-index: 4;
        transform: rotate(45deg);
        top: 30px;
        left: 50%;
        width: 20px;
        height: 20px;
        border-left: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        background: linear-gradient(135deg, rgb(255, 255, 255) 50%, rgba(255,255,255,0) 50%)
      }

    }
    
  } /* end of nav.desktop */

`

