import { css } from "styled-components"

export const footer = css`

  /* @TODO - extract this (repetitive) */
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  background-color: #F9F9F9;
  
  footer { 

    padding-top: 110px;
    position: relative;

    @media (max-width: 768px) {
      text-align: center;
    }

    /* For all column titles below */
    h4.title {
      font-size: 20px;
      margin-bottom: 28px;
      margin-top: 0;
    }

    .footer-logo-rhombus-background {
      position: absolute;
      top: -71px;
      width: 150px;
      height: 150px;
      background: #F9F9F9 0% 0% no-repeat padding-box;
      border-radius: 20px;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    }

    .footer-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -56px;
      height: 120px;
      width: 100px;
      background-size: cover;
      background-image: url(/Logo_footer.png);
      z-index: 2;
    }

    .footer-cols-container {

      display: flex;
      justify-content: space-around;
      margin-bottom: 63px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      .footer-col {

        display: flex;
        flex-direction: column;
        flex-basis: 200px;

        &:not(:first-child) {
          margin-left: 80px;

          @media (max-width: 768px) {
            margin-left: initial;
          }
        }

        @media (max-width: 768px) {
          margin-top: 75px;
        }

        /* For all footer navigation links in column order */
        a.footer-col-link {
          margin-bottom: 10px;
          font-size: 18px;
        }

        /* For all footer paragraph texts in columns */
        p.text {
          font-size: 18px;
          margin-top: 0px;
        }

        /* 
            First footer column 
            - Contact info & map
        */

        &--1 {

          @media (max-width: 768px) {
            align-items: center;
          }

          .footer-location {

            display: flex;
            margin-bottom: 33px;
            
            &__icon {
              background-image: url("/ico_location.png");
              background-size: cover;
              width: 32px;
              height: 32px;
              margin-right: 15px;
            }

            &__text {
              max-width: 233px;
              line-height: 26px;
              margin-top: 0px;

              @media (max-width: 768px) {
                strong {
                  font-weight: normal;
                  margin-left: -30px;
                }
              }
            }

          }

          .footer-email {
            
            display: flex;
            margin-bottom: 43px;

            a { 
              margin-right: 15px;
            }

            &__icon {
              background-image: url("/ico_email.png");
              background-size: cover;
              width: 32px;
              height: 23px;
            }

            &__address {
              margin-top: 0;
            }

          }

          .footer-map {
            width: 291px;
            height: 148px;
            border: 1px solid #48E4B7;
            background-image: url("/podroom-creative-gmaps.png");
            background-size: 106%;
            background-position-x: -10px;
          }

        }

        /* 
            Second footer column 
            - Services
        */

        &--2 {
          
          position: relative;
          flex-basis: 150px;

          .social-icons {

            position: absolute;
            top: 233px;
            left: 0;

            display: flex;

            @media (max-width: 768px) {
              position: relative;
              top: 0;
              margin-top: 20px;
              justify-content: center;
              left: initial;
            }

            a:not(:first-child) {
              margin-left: 15px;
            }

            .social-icon {
              width: 32px;
              height: 32px;
              background-size: cover;
            }

            &__ln {
              background-image: url("/ico_linkedin.png");
            }

            &__fb {
              background-image: url("/ico_facebook.png");
            }

            &__tw {
              background-image: url("/ico_twitter.png");
            }

            &__in {
              background-image: url("/ico_instagram.png");
            }

          }
        }

        /* 
            Third footer column 
            - Learning resources
        */

        &--3 {

        }

        /* 
            Fourth footer column 
            - Hire us
        */

        &--4 {

          flex-basis: 260px;

          .text {
            margin-bottom: 20px;
            line-height: 30px;
            
            @media (max-width: 768px) {
              max-width: 350px;
              margin: 0 auto;
              margin-bottom: 20px;
            }
          }
        }

      } /** end of .footer-col */

    } /** end of .footer-cols-container */
    
    .footer-bottom {

      @media (max-width: 768px) {
        background: #F2F2F2;
      }

      &__container {
        height: 35px;
        display: flex;
        /* justify-content: space-around; */
        justify-content: space-between;
        box-sizing: border-box;
        padding-top: 7px;

        @media (max-width: 768px) {
          flex-direction: column;
          height: auto;
          /* padding: 40px inherit; */
          padding-top: 95px;
          padding-bottom: 45px;
        }
      }

      a, span {
        font-size: 14px;
      }      

      &__left {

        @media (max-width: 768px) {
          order: 2;
          margin-top: 45px;

          span {
            color: gray;
          }
        }
        
      }

      &__right {

        display: flex;

        @media (max-width: 768px) {
          flex-direction: column;

          a {
            line-height: 30px;
          }
        }

        a:nth-of-type(2) {
          margin-left: 30px;

          @media (max-width: 768px) {
            margin-left: initial;
          }
        }

      }

    }

  }

`

