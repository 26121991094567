/**
 * @TODO - refactor this to array with type Fonts.
 *         It should have the 'element' or 'type' property for selecting the font.
 *         Link elements should be generated from the fonts array.
 */

export const podroomFonts = {
  dmSerifDisplay: {
    fontFamily: `'DM Serif Display', serif`,
    url: "https://fonts.googleapis.com/css?family=DM+Serif+Display:400,400i&display=swap"
  },
  dmSans: {
    fontFamily: `'DM Sans', sans-serif`,
    url: "https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap"
  }
}