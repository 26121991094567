import styled from "styled-components"

export const MainCtaBtnStyled = styled.div`

  .main-cta-btn {

    /* background-color: ${props => props.theme.color.black || "black"}; */
    /* @TEMP @TODO - hardcoded to black because of the bug with ThemeProvider  */
    background-color: #000; 
        
    span {
      color: white;
      font-size: 16px;
    }

    &__circle-icon {
      width: 13px;
      height: 13px;
      border: 3px solid white;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-top: -3px;
      transition: background 0.2s;
    }

    &:hover {
      .main-cta-btn__circle-icon {
        background-color: white;
      }
    }

    &__text {
      padding-left: 20px;
    }

  }

`