import styled, { css } from "styled-components"

export const logoWithNav = css`
  
  padding-top: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: filter 300ms;

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-top: 20px;
  }
  
  .main-logo {
    /** */

    .logo-container {
      img {

        @media (max-width: 768px) {
          width: 75%;
          padding-top: 17px;
        }
        
      }
    }

  }

  .main-top-nav {

    position: relative;

    @media (max-width: 768px) {
      flex-grow: 1;
    }

    .hamburger-menu {

      display: none;

      cursor: pointer;
      width: 60px;
      height: 40px;
      position: absolute;
      top: 15px;
      right: 0px;
      background-image: url("/hamburger-menu-icon--scaled--2.png");
      background-size: cover;
      z-index: 6;

      /* span {
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        opacity: 0;
        position: relative;
        transform-origin: 1px;
        background: white;
        transition: opacity 300ms;
      } */

      @media (max-width: 768px) {

        display: block;

      }

    }

  }
  
  

`

