import React from "react"
import { Link } from "gatsby"

import { FooterStyled } from "./css/FooterStyled.css";
import CenterContainer from "../../../../layouts/containers/CenterContainer/CenterContainer.component";
import FullWidthContainer from "../../../../layouts/containers/FullWidthContainer/FullWidthContainer.component";
import MainCtaButton from "../../_/buttons/MainCtaButton/MainCtaButton.component";


const Footer = () => (

  <FooterStyled>

    <CenterContainer> 

      <footer>

        <div className="footer-logo-rhombus-background"></div>
        <div className="footer-logo"></div>

        <div className="footer-cols-container">

          <div className="footer-col footer-col--1">
            <div className="footer-location">
              <a
                href="https://goo.gl/maps/bhh1c3kvJN7Tn6Cn9" 
                target="_blank" 
                className="footer-location__icon" />
              <p className="footer-location__text">Generala Milojka Lesjanina 29a,<br /><strong>Nis, Serbia</strong></p>
            </div>
            <div className="footer-email">
              <a href="mailto:contact@podroomcreative.com">
                <div className="footer-email__icon" />
              </a>
              <p className="footer-email__address">contact@podroomcreative.com</p>
            </div>
            <a className="footer-map" href="https://goo.gl/maps/bhh1c3kvJN7Tn6Cn9" target="_blank" />
          </div>{/* end of .footer-col--1 */}

          <div className="footer-col footer-col--2">
            <h4 className="title">Services</h4>
            <Link className="footer-col-link" to="/content-services/article-writing">Content Writing</Link>
            <Link className="footer-col-link" to="/guest-blogging-services">Guest Blogging</Link>
            <Link className="footer-col-link" to="/social-media-services">Social Media</Link>
            <div className="social-icons">
              <a href="https://www.linkedin.com/company/podroomcreative/" target="_blank">
                <div className="social-icons__ln social-icon" />
              </a>
              <a href="https://www.facebook.com/podroomcreative/" target="_blank">
                <div className="social-icons__fb social-icon" />
              </a>
              <a href="https://twitter.com/PodroomCreative" target="_blank">
                <div className="social-icons__tw social-icon" />
              </a>
              {/* <a href="#" target="_blank">
                <div className="social-icons__in social-icon" />
              </a> */}
            </div>
          </div>{/* end of .footer-col--2 */}

          <div className="footer-col footer-col--3">
            <h4 className="title">Learning resources</h4>
            <Link className="footer-col-link" to="/blog">Blog</Link>
            <Link className="footer-col-link" to="/guides">Guides</Link>
            <Link className="footer-col-link" to="/checklists">Checklists</Link>
            <Link className="footer-col-link" to="/quizes">Quizes</Link>
            <Link className="footer-col-link" to="/faq">F.A.Q.</Link>
          </div>{/* end of .footer-col--3 */}

          <div className="footer-col footer-col--4">
            <h4 className="title">Hire Us</h4>
            <p className="text">Psst, over here! Need some content? We'll hook you up.</p>
            <MainCtaButton buttonText="Make An Order" />
          </div>{/* end of .footer-col--4 */}

        </div>{/* end of .footer-cols-container */}

        <FullWidthContainer className="footer-bottom" backgroundColor={"#F2F2F2"}>

          <CenterContainer className="footer-bottom__container">

            <div className="footer-bottom__left">
              <span>Copyright © {new Date().getFullYear()} Podroom Creative Writing</span>
            </div>

            <div className="footer-bottom__right">
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            </div>

          </CenterContainer>

        </FullWidthContainer>

      </footer>

    </CenterContainer>

  </FooterStyled>

)

export default Footer;