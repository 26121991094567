import styled from "styled-components"
import { fullWidthContainer } from "./fullWidthContainer.css";

export interface FullWidthContainerStyledProps {
  backgroundColor?: string;
  inHalf?: boolean;
}

export const FullWidthContainerStyled = styled.div<FullWidthContainerStyledProps>`

  ${fullWidthContainer}

  /* Background */
  ${ props => {
    const backgroundColor = props.backgroundColor || props.theme.color.green;
    return props.inHalf
      ? `background: linear-gradient(to right, ${backgroundColor} 62vw, white 38vw);`
      : `background-color: ${backgroundColor} }`
  }}

  @media (max-width: 768px) {
    height: auto;
    background: none;
  }

`


