import React from "react"
import Media from 'react-media';

// @TODO - import logo directly (as ES6 module import)

const Logo = (props: any) => {

  return (

    <div className="logo-container">

      <Media 
        queries={{
          mobile: "(max-width: 768px)",
          desktop: "(min-width: 769px)"
        }}
        onChange={matches => console.log("Logo::matches", matches)}
        defaultMatches={{ desktop: true }}
      >

        {matches => (
          <>
            {matches.mobile && <img src={'/logo-header--3--responsive--3@2x.png'} alt="Logo" />}
            {matches.desktop && <img src={'/logo_header.png'} alt="Logo" />}
          </>
        )}

      </Media>
      
    </div>

  )
}

export default Logo;