import { css } from "styled-components"

/**
 * 
 *  podroomcreative.com global theme styles.
 * 
 */

export const theme = css`

  button {

    cursor: pointer;

    &.btn {

      /**

          Normalize    

      */

      border: none;
      width: auto;
      
      /* Normalize 'line-height'. Cannot be changed from 'normal' in Firefox 4+. */
      line-height: normal;
      
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      
      /* Corrects inability to style clickable 'input' types in iOS */
      -webkit-appearance: none;

      /**

          PodroomCreative styles

       */

      box-sizing: border-box;
      padding: 20px;
      font-size: 16px;

    }

  }

  .text-center {
    text-align: center;
  }

  .bold,
  .strong
  .text-bold
  .text-strong {
    font-weight: bold;
  }

  .clearfix {
   
    clear: both;

    &:after {
      clear: both; 
      content: "";
      display: table; 
    }

  }

  .clear {
    clear: both;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

`