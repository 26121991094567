import { css } from "styled-components"

export const centerContainer = css`
  width: 95%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  @media (max-width: 340px) {
    padding: 0 15px
  }

`
