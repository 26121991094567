import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import Media from 'react-media';

import { NavigationStyled } from "./css/NavigationStyled.css";

interface NavigationPropsI {
  isHamburgerMenuOpen: boolean;
  desktopMainMenuTextColor?: string;
}

const Navigation: React.FC<NavigationPropsI> = (props) => {

  const [viewportSizeClass, setViewportSizeClass] = useState<string>("desktop");

  const [subSubMenuOpen, setSubSubMenuOpen] = useState<boolean>(false);

  return (

    <NavigationStyled 
      isHamburgerMenuOpen={props.isHamburgerMenuOpen}
      desktopMainMenuTextColor={props.desktopMainMenuTextColor ?? undefined}
      >

      <Media 
        queries={{
          mobile: "(max-width: 768px)",
          desktop: "(min-width: 769px)",
        }}
        onChange={matches => {

          console.log("Navigation::matches", matches);

          if (matches.mobile) {
            setViewportSizeClass("mobile");
          }
          if (matches.desktop) {
            setViewportSizeClass("desktop");
          }
        }}
      >

      <nav className={viewportSizeClass}>

        <Link className="nav-item" to="/">Home</Link>

        <div className="nav-item nav-dropdown">

          <p className="nav-item--dropdown-p">Services</p>

          <div className="nav-dropdown__submenu submenu">

            <div 
              onClick={() => setSubSubMenuOpen(!subSubMenuOpen)}
              className="nav-dropdown__submenu__item has-sub-submenu">
              <img className="nav-dropdown__submenu__item__icon" src="/ico_content_writing.png" />
              <p className="nav-dropdown__submenu__item__cat-text">Content Writing</p>
              <div className={`nav-dropdown__submenu__item__links sub-submenu ${subSubMenuOpen ? 'open' : ''}`}>
                <Link to="/content-services/article-writing">Article writing</Link>
                <Link to="/content-services/academic-writing">Academic writing</Link>
                <Link to="/content-services/blog-writing">Blog Writing</Link>
                <Link to="/content-services/copywriting">Copywriting</Link>
                <Link to="/content-services/website-content-writing">Website Content Writing</Link>
                <Link to="/content-services/social-media-content-creation-and-maintenance">Social Media Content Creation And Maintenance</Link>
                <Link to="/content-services/press-release-writing">Press Release Writing Services</Link>
                <Link to="/content-services/newsletter">Newsletter</Link>
                <Link to="/content-services/ebook-writing">eBook Writing Services</Link>
                <Link to="/content-services/product-descriptions">Product Descriptions</Link>
                <Link to="/content-services/custom-outreach">Custom Outreach</Link>
                <Link to="/content-services/resume-writing">Resume Writing</Link>
                <Link to="/content-services/review-writing">Review Writing</Link>
                <Link to="/content-services/technical-writing">Technical Writing</Link>
                <Link to="/content-services/white-paper-writing">White Paper Writing</Link>
                <Link to="/content-services/email-writing">Email Writing Services</Link>
                <Link to="/content-services/content-writing">Content Writing</Link>
              </div>
            </div>
          </div>

        </div>

        <Link className="nav-item" to="/our-story">Our Story</Link>

        <Link className="nav-item" to="/hire-us">Hire Us</Link>
        
        <Link className="nav-item" to="/blog">Learning</Link>

      </nav>

      </Media>

    </NavigationStyled>

  )
}

export default Navigation;