import React from "react"
import { Link } from "gatsby"

import { MainCtaBtnStyled } from "./css/MainCtaBtnStyled.css";

interface MainCtaButtonProps {
  buttonText?: string,
  link?: string,
  netlifyForm?: boolean, // Whether it should be wrapped with <Link /> or pass it to Netlify.
}

const MainCtaButton: React.FC<MainCtaButtonProps> = (

  {
    buttonText, 
    link = "/hire-us", 
    netlifyForm = false
  }) => {
  
    const button = () => (
      <button 
        type={netlifyForm ? 'submit' : 'button'}
        className="btn main-cta-btn" 
        style={{backgroundColor: "#000"}} >
          <span className="main-cta-btn__circle-icon"></span>
          <span className="main-cta-btn__text">
            {buttonText || "Placeholder text"}
          </span>
      </button>
    );
    
    return (
      <MainCtaBtnStyled>
        
        {netlifyForm && 
          button()
        }
        
        {!netlifyForm && 
          <Link to={link}>
            {button()}  
          </Link>
        }
        
      </MainCtaBtnStyled>
    )
}

export default MainCtaButton;