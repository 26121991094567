import React from "react"
import Helmet from "react-helmet"
import { ThemeProvider } from "styled-components"
import { MainLayoutStyled, GlobalStyles } from "./MainLayout.css";
import { website } from "../utils/loaded";
import styled from "styled-components";

import { metaData } from "../config/metaData"
import { podroomTheme as theme } from "../theme/theme";

/**
 * @TODO - improve file naming for shared layouts.
 */

const WebsiteLoader = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #48e4b7;
  opacity: 1;
  transition: opacity 600ms;

  &.fully-loaded {
    display: none;
  }

  &.loaded {
    opacity: 0;
  }

  .loader-content {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .logo {
      height: 120px;
      width: 100px;
      background-size: cover;
      background-image: url("/Logo_footer.png");
    }

    div#loading-wave {
      position: relative;
      margin-top: 30px;
      text-align: center;
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: transparent;
        border-radius: 7px;
        margin: 0 3px;
        background: transparent;
        border: 4px solid black;
        animation: wave 1.3s linear infinite;

        &:nth-child(2) {
          animation-delay: -1.1s;
        }

        &:nth-child(3) {
          animation-delay: -0.9s;
        }

        /* &--1 {
          animation: wave-1 1.3s linear infinite;
        }

        &--2 {
          animation: wave-2 1.3s linear infinite;
          animation-delay: -1.1s;
        }

        &--3 {
          
          animation: wave-3 1.3s linear infinite;
          animation-delay: -0.9s;
        } */

      }
    }

    @keyframes wave {
      0%, 60%, 100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }

    }

    @keyframes wave-1 {
      0%, 60%, 100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }

      50% {
        background: yellow;
      }
    }

    @keyframes wave-2 {
      0%, 60%, 100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }

      50% {
        background: green;
      }
    }

    @keyframes wave-3 {
      0%, 60%, 100% {
        transform: initial;
      }

      30% {
        transform: translateY(-15px);
      }

      50% {
        background: blue;
      }
    }

  }


`

export interface metaObjectI {
  title?: string;
  description?: string;
  keywords?: string;
  lang?: string;
}

export interface ogObjectI {
  title?: string;
  description?: string;
  image?: string;
}

interface MainLayoutPropsI {
  meta?: metaObjectI
  og?: ogObjectI,
  canonicalUrl?: string,
}

const META = {
  TITLE: "title",
  DESCRIPTION: "description",
  KEYWORDS: "keywords",
  LANG: "lang"
}

const OG = {
  TITLE: "title",
  DESCRIPTION: "description",
  IMAGE: "image"
}

export class MainLayout extends React.Component<MainLayoutPropsI, {loading: boolean, fullyLoaded: boolean}> {

  constructor(props: any) {

    super(props);

    this.state = {
      loading: !website.loaded,
      fullyLoaded: website.loaded
    };

  }

  componentDidMount() {

    console.log("L O A D E D.", website.loaded);

    if (!website.loaded) {
      
      setTimeout(() => {

        website.loaded = true;

        this.setState({
          loading: false
        });

        console.log("website.loaded", website.loaded);
        console.log("this.state.loading", this.state.loading);
        console.log("this.state.loading", this.state.fullyLoaded);

        setTimeout(() => {
          this.setState({
            fullyLoaded: true
          });
        }, 300)

      }, 1000)

    }

  }

  /**
   * Try getting a value from props, for OG. 
   * If it fails, it gets the value from props, for Meta.
   * If that fails too, it gets the default website meta data from config.
   * @param type string - Use META and OG objects for passing this value. - Possible values: "title", "description" or "image"
   * @return string|undefined
   */
  getMetaValue(type: string): string|undefined {

    let metaValue;
    
    switch (type) {

      case META.LANG: {
        metaValue = this.props.meta?.lang
        ?? metaData.language;
      } break;

      case META.TITLE: {
        metaValue = this.props.meta?.title
        ?? metaData.title;
        metaValue += " | PodroomCreative";
      } break;

      case META.DESCRIPTION: {
        metaValue = this.props.meta?.description
        ?? metaData.description;
      } break;

      case META.KEYWORDS: {
        metaValue = this.props.meta?.keywords
        ?? metaData.keywords;
      } break;

      case OG.TITLE: { 
        metaValue = this.props.meta?.title
        ?? metaData.title;
        metaValue += " | PodroomCreative";
      } break;

      case OG.DESCRIPTION: {
        metaValue = this.props.og?.description 
        ?? this.props.meta?.description
        ?? metaData.description;
      } break;
      
      case OG.IMAGE: {
        metaValue = this.props.og?.image 
        ?? metaData.image;
      } break;

    }

    return metaValue;

  }
  
  render() {
    
    return (

      <ThemeProvider theme={theme}>

        <GlobalStyles></GlobalStyles>

        <MainLayoutStyled>
          
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            {this.props.canonicalUrl &&
              (<link rel="canonical" href={this.props.canonicalUrl} />)
            }

            <link href={theme.fonts.dmSerifDisplay.url} rel="stylesheet"></link>
            <link href={theme.fonts.dmSans.url} rel="stylesheet"></link>

            <title>{this.getMetaValue(META.TITLE)}</title>
            <meta name="description" content={this.getMetaValue(META.DESCRIPTION)} />
            {/* <meta name="keywords" content={this.getMetaValue(META.KEYWORDS)} /> */}
              
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@PodroomCreative" />
            <meta name="twitter:creator" content="@PodroomCreative" />
            <meta name="twitter:title" content={this.getMetaValue(OG.TITLE)} />
            <meta name="twitter:description" content={this.getMetaValue(OG.DESCRIPTION)} />
            <meta name="twitter:image" content={this.getMetaValue(OG.IMAGE)} />

            <meta property="og:title" content={this.getMetaValue(OG.TITLE)} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={this.getMetaValue(OG.IMAGE)} />
            <meta property="og:site_name" content="PodroomCreative" />
            <meta property="og:description" content={this.getMetaValue(OG.DESCRIPTION)} />

            <link rel="icon" type="image/png" sizes="41x41" href="/favicon.ico" />

            <html 
              lang={this.getMetaValue(META.LANG)} 
              prefix="og: http://ogp.me/ns#"
            />
            
          </Helmet>

          <main>
            
            {this.props.children}

            {!this.state.fullyLoaded && 
              <WebsiteLoader className={
                  `${!this.state.loading ? 'loaded' : ''}`
                  + `${this.state.fullyLoaded ? 'fully-loaded' : ''}`
                }>
                  <div className="loader-content">
                    <div className="logo" />
                    <div id="loading-wave">
                      <span className="dot dot--1"></span>
                      <span className="dot dot--2"></span>
                      <span className="dot dot--3"></span>
                    </div>
                  </div>
              </WebsiteLoader>
            }

          </main>

        </MainLayoutStyled>

      </ThemeProvider>

    );

  }

}