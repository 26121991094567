import React, { useState, useEffect } from "react"
import { Link } from "gatsby";

import Navigation from "../../navigation/Navigation.component"
import Logo from "../../logo/Logo.component";
import { LogoWithNavStyled } from "./css/LogoWithNavStyled.css";

interface LogoWithNavStyledPropsI {
  desktopMainMenuTextColor?: string;
}

const LogoWithNav = (props: LogoWithNavStyledPropsI) => {

  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  
  return (

    <LogoWithNavStyled isHamburgerMenuOpen={isHamburgerMenuOpen}>

      <div className="main-logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      
      <div className="main-top-nav">
        <div className="hamburger-menu" onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}>
          <span />
          <span />
          <span />
        </div>
        <Navigation 
          isHamburgerMenuOpen={isHamburgerMenuOpen} 
          desktopMainMenuTextColor={props.desktopMainMenuTextColor ?? undefined}
        />
      </div>

    </LogoWithNavStyled>
  
  )
}

export default LogoWithNav;