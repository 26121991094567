import React from "react"
import { PageSectionStyled, PageSectionStyledProps } from "./PageSectionStyled.css";
import FullWidthContainer from "../containers/FullWidthContainer/FullWidthContainer.component";

const PageSection: React.FC<PageSectionStyledProps> = (props) => (

  <PageSectionStyled 
    {...props}
    >
      
      <section>
       {props.children}
      </section>

  </PageSectionStyled>

)

export default PageSection;