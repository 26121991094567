import styled, { createGlobalStyle } from "styled-components"
import { normalize } from 'styled-normalize'

import { centerContainer } from "./containers/CenterContainer/css/centerContainer.css";
import { PodroomThemeType } from "../theme/theme";
import { theme } from "./theme.css";

// These didn't work. Imports moved to ReactHelmet as link elements.
// @import url('${({ theme }): string => theme.fonts.dmSerifDisplay.url}')
// @import url('${({ theme }): string => theme.fonts.dmSans.url}')

export const GlobalStyles = createGlobalStyle<{ theme: PodroomThemeType }>`

  ${normalize}

  html {
    
  }

  ::selection { 
    background: #48E4B7; 
  }

  body { 
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
  }

  a, p, span, li {
    font-size: 16px;
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.fonts.dmSans.fontFamily};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({theme}) => theme.fonts.dmSerifDisplay.fontFamily};
    color: ${({ theme }) => theme.color.black};
    font-weight: normal;
  }

  a, p, span,
  h1, h2, h3, h4, h5,
  strong, b, i, u {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin: 0;
  }

  ul, ol, li { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  @media screen and (max-width: 768px) {
    html {
      max-width: 100vw;
      overflow-x: hidden;
    }
  }

  ${theme}

`

export const MainLayoutStyled = styled.div`
  
  ${centerContainer}

  background-color: white;
  min-height: 100vh;

`