import styled, { FlattenSimpleInterpolation } from "styled-components"

/**
 * @param {string} extendStyles - Paste your custom CSS here. Will extend the styles of this styled component. 
 * @param {boolean} fullWidth - When set to true, both padding top and padding botom won't be set. Otherwise they default to 100px respectively.
 * @param {number} paddingTop - ...
 * @param {number} paddingBottom - ...
 */
export interface PageSectionStyledProps {
  extendStyles?: FlattenSimpleInterpolation;
  fullWidth?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
}

export const PageSectionStyled = styled.section<PageSectionStyledProps>`

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  section {

    ${props => 
      props.fullWidth
        ? `
          width: 100vw;
          position: relative;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        `
        : ``
    }

    padding-top: 
      ${props => props.paddingTop 
        ? `${props.paddingTop}px;` 
        : `${props.fullWidth ? '' : '100px;'}`};

    padding-bottom: 
      ${props => props.paddingBottom 
        ? `${props.paddingBottom}px;` 
        : `${props.fullWidth ? '' : '100px;'}`};

    h2 {
      font-size: 60px;
    }
    p {
      font-size: 16px;
    } 

    .section-title {
      margin-bottom: 60px;
    }

  }

  ${props => props.extendStyles || ''}

`